@use "../abstracts/variables";

.color-picker {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    &>li {
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        margin: 2px;

        outline: 0;
        outline-style: inset;

        &:hover,
        &.selected {
            opacity: 0.8;
            cursor: pointer;

            outline: 1px solid variables.$black;
        }
    }
}