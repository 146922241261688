@use "../abstracts/variables";

.items-table {
    margin-top: 8px;

    border-radius: 12px;
    border: 1px solid variables.$outline;
    width: 600px;

    overflow: hidden;

    &>table {
        width: 100%;
        padding: 12px;

        tr {
            td {
                padding: 8px 0;
                padding-left: 18px;
            }

            td:last-of-type {
                padding-right: 18px;
            }
        }

        // border-spacing: 8px;
        border-collapse: collapse;
        border-spacing: 0;

        tbody {
            td {
                text-align: center;

                button {
                    margin: 0 auto;
                }
            }
        }

        tfoot {
            // background: variables.$outline;
            border-top: 1px solid variables.$outline;

            td>button {
                width: 36px;
                height: 36px;
                padding: 0;
                margin: 0 auto;
            }

            input,
            select {
                border-radius: 0;
                border: 0;
                // border-bottom: 1px solid variables.$outline;
                border-bottom: 1px solid black;
            }
        }

        // TODO: create custom style file
        // item styles
        .item-color {
            display: inline-block;

            width: 18px;
            height: 18px;
            border-radius: 4px;
            border: 1px solid black;

            // margin-left: 8px;
        }
    }
}