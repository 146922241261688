@use "../abstracts/variables";

.images-editor {
    display: flex;
    flex-flow: row;

    width: 100%;
    overflow-x: overlay;
    overflow-y: hidden;

    box-sizing: border-box;
    padding: 12px 0;

    height: 200px;

    &>li {
        position: relative;
        width: 130px;
        min-width: 130px;

        display: flex;

        &>input {
            flex: 1;

            &:disabled {
                opacity: 0.5;

                &::after {
                    content: "Nahrávání..." !important;
                }
            }

            &::after {

                content: "Přidat fotku";
                @include variables.font;

                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;

                background: variables.$white;

                font-size: 0.8rem;
                font-weight: 300;

                position: absolute;
                left: 0;
                top: 0;
                border-radius: 12px;
                border: 1.4px solid variables.$black;

                padding: 12px;
                box-sizing: border-box;
                white-space: break-spaces;
                text-align: center;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &>div {
            width: 100%;

            &.dragging {
                opacity: 0.5;
            }

            &>button.delete {
                position: absolute;
                left: 6px;
                top: 6px;

                width: 32px;
                height: 32px;

                padding: 0;
                border-radius: 8px;
            }

            &>button.data {
                position: absolute;
                left: 6px;
                bottom: 6px;

                width: 32px;
                height: 16px;

                padding: 0;
                border-radius: 4px;

                font-size: 0.6rem;
            }

            &>img {
                width: 100%;
                height: 100%;
                flex: 1;

                border-radius: 12px;

                object-fit: cover;
            }
        }

        &:not(&:last-of-type) {
            margin-right: 18px;
        }
    }
}