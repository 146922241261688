@use "../layout";

.page-areas {
    @include layout.map-page-layout;

    &-map {
        .map {
            border-radius: 24px;
        }
    }

    &-sidebar {
        display: grid;
        grid-template-rows: auto minmax(0, 1fr) auto;

        &-heading {
            display: flex;
            align-items: center;

            h1 {
                font-weight: 500;
                font-size: 1.5rem;
            }

            span {
                margin-left: 8px;

                font-weight: 300;
                font-size: 1.125rem;
            }
        }

        &-actions {
            width: 100%;
            justify-self: flex-end;

            display: grid;
            grid-auto-flow: row;
            row-gap: 14px;

            &>button {
                width: 100%;
            }
        }

        &>.area-cards {
            margin-top: 42px;
            overflow: hidden;

            &>ul {
                height: 100%;

                box-sizing: border-box;
                overflow: scroll;
                padding-bottom: 180px;

                &>li {
                    margin-bottom: 32px;
                }
            }

            position: relative;

            &::after {
                content: "";

                position: absolute;
                bottom: 0;
                left: 0;
                display: block;

                height: 180px;
                width: 100%;

                background: linear-gradient(0deg, #FFFFFF 27.09%, rgba(255, 255, 255, 0) 99.45%);

                pointer-events: none;
                user-select: none;
            }
        }
    }
}