@use "../abstracts/variables";

.large-print-tag {
    width: 210mm;
    height: 297mm;

    display: grid;

    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);

    &.preview {
        // width: auto !important;
        // height: auto !important;

        // max-width: 500px !important;
        width: calc(210mm/2) !important;
        height: calc(297mm/2) !important;

        aspect-ratio: 1.414 !important;

        grid-template-rows: repeat(1, 1fr) !important;
        grid-template-columns: repeat(1, 1fr) !important;

        &>.large-print-tag-table {
            grid-column: 1/2 !important;
            grid-row: 1/2 !important;
        }
    }


    &-table {
        height: 100%;
        font-size: 3.2mm;

        &.area-1 {
            grid-column: 1/2;
            grid-row: 1/2;
        }

        &.area-2 {
            grid-column: 2/3;
            grid-row: 1/2;
        }

        &.area-3 {
            grid-column: 1/2;
            grid-row: 2/3;
        }

        &.area-4 {
            grid-column: 2/3;
            grid-row: 2/3;
        }

        &-images {
            display: table-cell;

            &>div {
                display: grid;

                &.double {
                    grid-template-columns: repeat(2, 1fr);
                }

                &.triple {
                    grid-template-columns: repeat(3, 1fr);
                }

                grid-template-rows: 28mm;

                column-gap: 0.42rem;

                &>img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }


                // image drag
                &>div {
                    width: 100%;
                    height: 100%;

                    cursor: all-scroll;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        &-description {
            &>td:last-of-type {
                display: table-cell;
                vertical-align: top;
            }
        }

        &-map {
            height: auto !important;

            position: relative;

            // pointer-events: none !important;

            .mapboxgl-ctrl-logo,
            .mapboxgl-ctrl-attrib {
                display: none !important;
            }

            div.coordinates {
                pointer-events: none;

                position: absolute;
                left: 50%;
                transform: translateX(-50%);

                bottom: 6px;

                background: variables.$white-dark;
                border: 2px solid variables.$outline;

                white-space: nowrap;
                z-index: 99999;

                padding: 3px 10px;
                font-weight: 500;

                font-size: 0.8rem;
                border-radius: 4px;
            }
        }

        &-description+tr {
            // height: auto !important;
            height: 9.4mm !important;

            &>td {
                position: relative;
                box-sizing: border-box;

                &:after {
                    position: absolute;

                    content: "";
                    width: 6px;
                    height: calc(50%);

                    background: white;

                    right: -2px;
                    top: 0.1px;

                    z-index: 9999;
                }

                &:before {
                    position: absolute;

                    content: "";
                    width: 6px;
                    height: calc(50%);

                    background: white;

                    right: -2px;
                    bottom: 0.1px;

                    z-index: 9999;
                }
            }
        }

        & {
            border-collapse: collapse;
            table-layout: fixed;
        }

        &>tbody>tr>td {
            border: 1px solid black;
        }

        &>tbody {
            &>tr {
                height: 3.4mm;

                &>td {

                    padding: 2mm;
                    box-sizing: border-box;

                    &>img {
                        width: 100%;
                        height: 44mm;

                        object-fit: cover;
                    }
                }
            }
        }
    }
}