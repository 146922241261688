.findings-popup {
    border-radius: 24px;

    padding: 2px 8px;
    padding-top: 26px;

    box-sizing: border-box;

    display: grid;
    grid-template-rows: auto auto auto auto 1fr auto;
    grid-auto-flow: row;
    row-gap: 10px;

    width: 200px;
    height: 330px;

    position: relative;

    &-heading {
        display: flex;
        flex-flow: row;
        align-items: center;

        &>h2 {
            font-size: 1rem;
            line-height: 1rem;
            flex: 1;
        }

        &>span {
            font-size: 0.7rem;
            opacity: 0.5;
            line-height: 0.6rem;
        }
    }

    &-actions {
        &>button {
            width: 100%;
        }
    }

    &>.swiper,
    &>img {
        width: 100%;
        height: 110px;
        border-radius: 4px;
    }

    &>img {
        object-fit: contain;
    }

    &>ul {
        &>li {
            line-height: 0.95rem;
        }
    }

    &-description {
        line-height: 0.9rem;

        position: relative;

        display: flex;
        flex-flow: column;

        overflow: hidden;

        p {
            margin-top: 4px;
            overflow-y: scroll;
            overflow-x: hidden;
            flex: 1;
            opacity: 0.7;

            padding-bottom: 20px;
        }

        &::after {
            content: "";

            position: absolute;
            bottom: 0;
            left: 0;
            display: block;

            height: 10px;
            width: 100%;

            background: linear-gradient(0deg, #FFFFFF 27.09%, rgba(255, 255, 255, 0) 99.45%);

            pointer-events: none;
            user-select: none;
        }
    }

    &-close {
        position: absolute;
        left: -2px;
        top: -2px;

        width: 20px;
        height: 20px;

        font-size: 0.75rem;

        padding: 0;
        border-radius: 6px;
    }
}