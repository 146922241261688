@use "../abstracts/variables";

body {
    margin: 0;
    @include variables.font;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    // @include variables.font;
}

textarea {
    @include variables.font;
}

li,
ul {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}