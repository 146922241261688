@use "../abstracts/variables";

.print-area-modal {
    background: variables.$white;
    padding: 18px 24px;
    border-radius: 12px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;

    row-gap: 18px;
    column-gap: 12px;

    max-width: 320px;

    &>h2 {
        grid-column: 1/3;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
    }

    &>.areas {
        grid-column: 1/3;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;

        column-gap: 8px;
        row-gap: 8px;

        &>button {
            aspect-ratio: 1/1.4142;
        }
    }
}