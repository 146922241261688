.swiper {
    width: 100%;

    img.swiper-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-pagination {
        bottom: 0 !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #ffffff !important;
    }
}