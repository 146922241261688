@use "../abstracts/variables";

.map-point-picker {
    display: grid;

    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr auto;

    column-gap: 20px;
    row-gap: 6px;

    .mapboxgl-canvas-container {
        cursor: crosshair !important;
    }

    .map {
        grid-column: 1/3;

        border-radius: 12px;
    }

    &>div {
        &>label {
            font-size: 0.7rem;
        }

        &>input {
            font-size: 0.8rem;
            padding: 4px 8px;

            border: none;
            border-bottom: 1px solid variables.$outline;
            border-radius: 0;

            margin-left: 6px;
            
            min-width: 146px;
        }
    }

    width: 100%;
    height: 100%;
}