@use "../components/header";

@mixin map-page-layout() {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 100%;
    column-gap: 52px;

    padding: 42px 36px;
    box-sizing: border-box;

    height: calc(100vh - header.$height);
}