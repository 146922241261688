$outline: #E1E2E2;

$black: #202526;
$white: #FFFFFF;
$white-dark: #F7F7F7;
$white-darker: $outline;

$red: #E50D0D;


$modal-index: 1000;
$filter-dropdown-index: 200;
$map-controls-index: 100;
$map-loading-index: 500;

@mixin font {
    font-family: 'Poppins', sans-serif;
}