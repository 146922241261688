.finding-rating {
    display: flex;
    flex-flow: row;
    align-items: center;

    &>li {
        width: 18px;
        height: 18px;

        &>img {
            width: 100%;
            height: 100%;
        }
    }

    &-input {
        &>li:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }
}