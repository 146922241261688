@use "../abstracts/variables";

.map {
    &-controls {
        display: grid;
        grid-auto-flow: row;
        row-gap: 8px;

        position: absolute;
        z-index: variables.$map-controls-index;

        top: 14px;
        left: 14px;
    }

    &-filters {
        top: 14px;
        right: 14px;

        position: absolute;
        z-index: variables.$map-controls-index;
    }

    &-loading {
        position: absolute;

        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        z-index: variables.$map-loading-index;

        background: white;
        opacity: 0;
        transition: 100ms opacity linear;

        pointer-events: none;
        user-select: none;

        &.active {
            opacity: 1;
        }
    }
}