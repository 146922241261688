.default-location-settings {
    display: flex;
    flex-flow: column;

    align-items: flex-start;

    .map {
        border-radius: 24px;

        height: 640px;
        width: 1000px;

    }

    .map,
    &>button {
        margin-top: 12px;
    }
}