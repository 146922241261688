.map-style-manager {
    position: relative;

    &-styles {
        position: absolute;
        top: 0;
        left: calc(100% + 8px);
        
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 4px;
        row-gap: 2px;

        &-entry {
            white-space: nowrap;            
            width: 136px;
            text-align: center;
            
            & > button {
                width: 100%;
            }
        }
    }
}