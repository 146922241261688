.page-login {
    min-height: 100vh;
    display: flex;

    justify-content: center;
    align-items: center;

    flex-flow: column;

    form {
        margin-top: 36px;

        display: flex;
        flex-flow: column;
        align-items: center;

        width: 252px;

        &>.form-control {
            margin-top: 12px;
            width: 100%;
        }

        &>button {
            margin-top: 24px;
        }
    }
}