.area-card {
    width: 260px;

    display: grid;
    grid-template-areas: 'image name''image findings';

    grid-template-columns: 62px 1fr;
    grid-template-rows: 1fr 1fr;

    height: 62px;

    row-gap: 2px;
    column-gap: 16px;


    transition: transform 250ms;

    &:hover {
        transform: scale(0.95);
        cursor: pointer;
    }

    img {
        border-radius: 12px;
        width: 100%;
        height: 100%;
        grid-area: image;

        object-fit: cover;
    }

    &-name {
        grid-area: name;
        font-weight: 500;
        font-size: 1.125rem;

        white-space: nowrap;
        text-overflow: ellipsis;

        overflow: hidden;

        align-self: flex-end;
    }

    &-findings {
        grid-area: findings;
        font-weight: 300;
        font-size: 1rem;

        opacity: 0.7;
    }
}