@use "../abstracts/variables";

.map-finding-filters {
    background: variables.$white;
    border-radius: 12px;

    display: flex;
    flex-flow: row;

    border: 2px solid variables.$outline;

    &-active {
        width: 4px;
        height: 4px;
        background: variables.$black;
        border-radius: 4px;

        margin-right: 4px;
    }

    &>div {
        padding: 6px;
        position: relative;

        min-width: 100px;

        display: flex;

        &>span {
            padding: 4px;
            border-radius: 8px;

            flex: 1;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;

            user-select: none;
            cursor: pointer;

            &>img {
                width: 14px;
                height: 14px;

                margin-left: 4px;
            }
        }

        &>ul {
            display: none;
            width: 100%;

            position: absolute;
            left: 0;
            top: 100%;

            background: variables.$white;
        }

        &:hover {
            &>span {
                background: variables.$white-dark;
            }

            &>ul {
                display: block;
            }
        }
    }
}