@use "../layout";

.page-area {
    @include layout.map-page-layout;

    &-info {
        width: 820px;
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: auto auto auto minmax(0, 1fr) auto;
        justify-items: flex-start;
        row-gap: 24px;

        position: relative;

        &-photos {
            &>ul {
                margin-top: 8px;

                height: 120px;
                display: flex;
                flex-flow: row;

                max-width: 100%;

                overflow-x: overlay;
                overflow-y: hidden;

                &>li {
                    margin-right: 16px;

                    &>a {
                        height: 100%;

                        &>img {
                            border-radius: 16px;
                            height: 100%;
                        }
                    }
                }

            }
        }

        &-findings {
            width: 100%;
            display: grid;
            grid-template-rows: auto 1fr;

            &>.findings-table {
                margin-top: 12px;
            }
        }

        &-buttons {
            display: flex;
            flex-flow: row;

            &>button {
                margin-right: 12px;
            }
        }

        &-actions {
            position: absolute;
            right: 0;
            top: 0;

            display: flex;

            &>button:not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }

    &-map {
        .map {
            border-radius: 24px;
        }
    }
}