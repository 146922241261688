.small-print-tag {

    font-family: 'Oswald', sans-serif !important;

    // border: 2px solid black;

    // border-radius: 12px;
    // margin: 4px;

    width: 3.81cm;
    // height: 2.51cm;

    box-sizing: border-box;

    text-align: center;

    &-header {
        display: flex;

        &>span:first-of-type {
            flex: 1;
            text-align: left;
        }
    }

    &>div,
    &>p {
        padding: 1.0pt 7.4pt;
        font-size: 6.5pt;

        box-sizing: border-box;
    }

    &>h2 {
        padding: 0.4pt 0;
        margin: 0.3pt 0;

        color: white;
        background: black;

        width: 100%;

        // font-size: 0.6rem;
        font-size: 12pt;
    }
}