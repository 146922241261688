@use "../abstracts/variables";

.findings-table {
    width: 100%;
    max-height: 100%;

    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;

    position: relative;

    &-reset-filters {
        position: absolute;
        right: 8px;
        bottom: 8px;

        &>button {
            font-size: 0.75rem;
        }
    }

    &-heading,
    &-body {
        width: 100%;
    }

    &-heading,
    &-body>li {
        display: flex;
        align-items: center;

        &>span,
        &>div {
            flex: 1;
            text-align: center;
            user-select: none;

            display: flex;
            justify-content: center;
        }

        &>div {
            &:hover {
                cursor: pointer;
            }

            display: flex;
            align-items: center;
            justify-content: center;

            // filter icon
            &>img {
                height: 18px;
                width: 18px;
                object-fit: contain;

                margin-right: 4px;
            }

            &>.filter-active-circle {
                width: 6px;
                height: 6px;
                background: variables.$black;
                border-radius: 6px;

                margin-right: 8px;
            }
        }
    }

    &-heading {
        height: 44px;
        background: variables.$white-dark;

        &>div {
            position: relative;

            .findings-items-filter {
                position: absolute;
                min-width: 100%;

                top: calc(100% + 4px);
                left: 0;

                z-index: variables.$filter-dropdown-index;
            }
        }
    }

    &-body {
        overflow-y: overlay;

        &>li {
            height: 52px;

            &:hover {
                background: variables.$white-dark;
                cursor: pointer;
            }

            &.selected {
                background: variables.$white-darker;
            }
        }

        &>li:not(li:last-of-type) {
            border-bottom: 2px solid variables.$outline;
        }

        padding-bottom: 60px;
    }

    border-radius: 24px;
    border: 2px solid variables.$outline;
}