@use "../abstracts/variables";

input,
select,
textarea {
    padding: 10px 14px;

    border: 1px solid variables.$outline;
    border-radius: 12px;

    font-size: 1rem;
}