@use "../abstracts/variables";

.edit-finding {
    width: 100%;
    max-width: 520px;

    padding: 32px;
    border-radius: 24px;

    background: variables.$white;

    position: relative;

    &-actions {
        position: absolute;

        top: 24px;
        right: 24px;

        display: flex;

        &>button:not(:first-of-type) {
            margin-left: 8px;
        }
    }

    &-images {
        margin-top: 24px;
    }

    &>form {
        margin-top: 24px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 18px;
        row-gap: 12px;

        &>button {
            grid-column: 1/3;

            &:first-of-type {
                margin-top: 24px;
            }
        }
    }
}