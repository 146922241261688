@use "../abstracts/variables";

.large-print-tag-preview-modal {
    background: variables.$white;
    // padding: 18px 24px;
    padding: 24px;
    border-radius: 12px;

    display: flex;

    gap: 24px;

    text-align: left;

    &-aside {
        width: 200px;

        border-right: 1px solid variables.$outline;
        padding-right: 24px;

        display: flex;
        flex-flow: column;
        align-items: flex-start;

        &>span {
            font-size: 0.9rem;
        }

        &>div.images {
            margin-top: 24px;
            width: 100%;

            &>div {
                display: flex;
                gap: 8px;
                margin-top: 8px;
            }

            button {
                flex: 1
            }
        }

        &>div.submit {
            flex: 1;
            justify-content: flex-end;
            width: 100%;

            display: flex;
            flex-flow: column;

            gap: 8px;
        }
    }
}