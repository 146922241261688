@use "../abstracts/variables";

.form-control {
    display: flex;
    flex-flow: column;

    label {
        font-size: 0.85rem
    }

    &-errors {
        font-size: 0.7rem;
        color: variables.$red;

        height: 8px;
        margin-top: 2px;
    }

    input,
    textarea,
    select,
    .custom-input {
        margin-top: 4px;
    }
}