@use "../abstracts/variables";

$height: 80px;

header {
    height: $height;

    width: 100%;
    display: flex;

    padding: 0 36px;
    box-sizing: border-box;

    align-items: center;

    border-bottom: 2px solid variables.$outline;

    &>div:first-of-type {
        flex: 1;

        display: flex;
        align-items: center;

        cursor: pointer;

        img {
            margin-right: 8px;
            height: 32px;
            width: 32px;
            object-fit: contain;
        }

        &>span {
            font-weight: 500;
            font-size: 1.5rem;
        }
    }
}