@use "../abstracts/variables";

.modal {
    z-index: variables.$modal-index;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    background: rgba(0, 0, 0, 0.7);

    display: flex;
    justify-content: center;
    align-items: center;
}