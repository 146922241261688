@use "../abstracts/variables";

.create-findings-entry {
    display: grid;
    grid-template-columns: 1fr 460px;
    grid-template-rows: auto 1fr auto;

    row-gap: 6px;
    column-gap: 24px;

    &>.map-point-picker {
        height: 100%;

        grid-column: 1/2;
        grid-row: 1/3;

        min-height: 300px;
    }

    &-gps {
        grid-column: 1/2;
        grid-row: 3/4;

        display: grid;
        grid-template-columns: 1fr 1fr;

        column-gap: 24px;
        row-gap: 6px;
    }

    &-images {
        &>ul {
            height: 100px;

            &>li {
                width: 80px;
                min-width: 80px;

                &>input {
                    &::after {
                        @include variables.font;

                        font-size: 0.6rem;
                        font-weight: 300;

                        border-radius: 8px;
                    }
                }

                &>button {
                    position: absolute;
                    left: 6px;
                    top: 6px;

                    width: 32px;
                    height: 32px;

                    padding: 0;
                    border-radius: 8px;
                }

                &>img {
                    height: 100%;
                    flex: 1;

                    border-radius: 8px;

                    object-fit: cover;
                }

                &:not(&:last-of-type) {
                    margin-right: 12px;
                }
            }
        }
    }

    &-form {
        margin-top: 12px;

        grid-row: 2/4;

        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 24px;

        &>div {
            margin-top: 2px;

            display: flex;
            flex-flow: column;

            &>input,
            &>select,
            &>textarea {
                margin-top: 4px;
            }
        }

        &>div.textarea {
            grid-column: 1/3;

            &>textarea {
                height: 24px;
            }
        }
    }
}