.page-settings {
    padding: 32px 48px;

    &-items {
        margin-top: 48px;

        display: flex;
        flex-flow: column;

        &>div {
            margin-right: 48px;
            margin-top: 24px;
        }
    }

    &-defaults {
        display: grid;
        grid-auto-flow: row;
        row-gap: 24px;

        &>div {
            display: flex;
            flex-flow: column;
            max-width: 320px;

            &>input {
                margin-top: 4px;
            }
        }

        &>.user-settings,
        &>.detector-settings {
            max-width: 320px;
        }
    }

    &-location {
        margin-top: 48px;
    }
}