@use "../abstracts/variables";

.create-findings {
    width: 100%;
    max-width: 1020px;
    background: variables.$white;

    padding: 32px;
    border-radius: 24px;

    &-list {
        grid-column: 1/3;
        max-width: 100%;

        &>div {
            margin-top: 4px;

            display: grid;
            grid-template-columns: 1fr auto;

            &>ul {
                overflow-x: overlay;
                margin-right: 12px;

                &>li {
                    margin-right: 4px;
                    position: relative;

                    &>button {
                        padding: 0px;
                        width: 42px;
                        height: 42px;
                    }

                    &>button.remove-finding {
                        position: absolute;
                        width: 16px;
                        height: 16px;

                        font-size: 0.65rem;

                        left: 4px;
                        top: 4px;
                    }

                }
            }
        }
    }

    &-finding {
        // border-top: 2px solid variables.$outline;
        // border-bottom: 2px solid variables.$outline;

        // padding: 24px 0;

        grid-column: 1/3;

        &>button {
            width: 100%;
            margin-top: 16px;
        }
    }

    &-action {
        display: grid;
        grid-auto-flow: column;
        column-gap: 16px;
        width: 100%;

        grid-column: 1/3;
        grid-template-columns: repeat(2, 1fr);

        max-width: 460px;
        justify-self: flex-end;
    }

    &>form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 18px;

        &>button {
            grid-column: 1/3;
            margin-top: 18px;
        }
    }
}