@use "../layout";

.page-create-area {
    @include layout.map-page-layout;

    &-sidebar {
        width: 300px;

        &>form {
            margin-top: 32px;

            &>.form-control {
                margin-top: 18px;
            }

            &>button {
                margin-top: 24px;
            }
        }
    }

    &-map {
        border-radius: 24px;
        overflow: hidden;
    }

    .images-editor {
        height: 120px;

        li {
            min-width: 80px;
            width: 80px;
        }
    }
}