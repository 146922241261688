@use "../abstracts/variables";

button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;

    border-radius: 12px;
    border: none;

    font-size: 1rem;

    transition: background 250ms, opacity 250ms;

    &:hover {
        cursor: pointer;
    }

    &.small {
        font-size: 0.85rem;
        padding: 6px 14px;
        border-radius: 8px;
    }

    &.primary {
        background-color: variables.$black;
        color: variables.$white;
        border: 2px solid variables.$black;

        &:hover:enabled {
            background-color: variables.$black;
            opacity: 0.8;
        }
    }

    &,
    &.secondary {
        background-color: variables.$white;
        color: variables.$black;
        border: 2px solid variables.$outline;

        &:hover:enabled {
            background-color: variables.$outline;
        }
    }

    &.icon {
        padding: 6px;

        &.active {
            background-color: variables.$black;
            border: 2px solid variables.$black;

            &>img {
                filter: invert(1);
            }
        }

        &>img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
    }

    &:disabled {
        opacity: 0.7;
        position: none;

        &:hover {
            cursor: auto !important;
        }
    }
}