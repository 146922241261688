@use "../abstracts/variables";

.findings-items-filter {
    padding: 6px 16px;
    box-sizing: border-box;
    border: 2px solid variables.$outline;
    border-radius: 8px;
    background: variables.$white;

    &>li {
        margin: 3px 0;

        display: flex;
        align-items: center;

        cursor: pointer;
        user-select: none;

        &>div.filter-checkbox {

            &.checked {
                background: variables.$black;
            }

            width: 12px;
            height: 12px;
            border-radius: 4px;
            outline: 1px solid variables.$black;

            margin-right: 8px;
        }
    }
}